import type { SupabaseClient, UserAttributes } from '@supabase/supabase-js'
import { isNil } from 'lodash-es'
import type { IOption } from '~/src/interfaces/IOption'
import type { KeyValueT } from '~/src/types/KeyValue'
import type { paths } from '~/src/api/schema'

export const round = (value: number, decimals = 2) => {
  return Number(Math.floor(Number(value + 'e' + decimals)) + 'e-' + decimals)
}

export const roundDown = (value: number, decimals = 2, pure = false) => {
  const newValue = value / 1000
  let sign = 'K'

  if (newValue < 1) {
    return `${value}`
  }

  let num = round(newValue, decimals)
  if (num > 999) {
    num = roundDown(num, 2, true) as number
    sign = 'M'
  }

  if (pure) { return num }

  return `${num}${sign}`
}

export const calculateStep = (min: number, max: number) => {
  const logMin = Math.log10(min || 1)
  const logMax = Math.log10(max)
  const step = Math.pow(10, (logMax - logMin) / 100)

  return step > 1 ? Math.round(step) : step
}

export const normalizeRangeInput = (value: number, step: number, min: number, max: number) => {
  let _step = step
  if (step === 0) { _step = 10 }
  if (value < min) { return min }
  if (value > max) { return max }
  if (value === 0) { return 0 }
  return Math.round(value / _step) * _step
}

export const hasOwnProperty = (obj: unknown, property: string) => Object.prototype.hasOwnProperty.call(obj, property)

interface IUserOptions {
  emailRedirectTo?: string
}

export const extractLangFromLocale = (locale: string) => locale.split('-')[0]

export const supabaseUpdateUser = async (supabase: SupabaseClient, attributes: UserAttributes, options: IUserOptions = {}) => {
  const updatedUser = await supabase.auth.updateUser(attributes, options)
  if (updatedUser.error) {
    throw new Error(updatedUser.error.message)
  }
  return updatedUser
}

export const enumToOptions = (_enum: Record<string | number, unknown>, t: () => void, translationPath: string): IOption[] => (Object.values(_enum).filter(value => typeof value === 'string') as string[]).map((value: string) => { return { key: _enum[value] as string, value: t(`${translationPath}-${_enum[value]}`) } })

export const enumKeys = (_enum: { [k: string]: string | number }) => {
  return Object.keys(_enum).filter(k => typeof _enum[k] === 'number')
}

export const enumValues = (_enum: { [k: string]: string | number }) => {
  return enumKeys(_enum).map(k => _enum[k]!).sort()
}

export const isNumeric = (num: unknown) => (typeof (num) === 'number' || (typeof (num) === 'string' && num.trim() !== '')) && !isNaN(num as number)

export const isPending = (invitedAt?: string | null, confirmedAt?: string | null): boolean => {
  if (confirmedAt) { return false }
  return !isNil(invitedAt)
}

export const arrayToKV = <T>(arr: T[], key: keyof T, value: keyof T): KeyValueT => {
  return arr.reduce((obj, item) => {
    const itemKey = String(item[key])

    obj[itemKey] = String(item[value])
    return obj
  }, {} as KeyValueT)
}

export const arrayToOptions = <T>(arr: T[], key: keyof T, value: keyof T, append?: string): IOption[] => {
  return arr.reduce((arr, item) => {
    const itemKey = append ? `${append}${String(item[key])}` : String(item[key])
    const itemValue = String(item[value])

    arr.push({ key: itemKey, value: itemValue })
    return arr
  }, [] as IOption[])
}

export const objectToOptions = <T extends Record<string, string>>(obj: T): IOption[] => {
  return Object.keys(obj).reduce((arr, item) => {
    const itemKey = String(item)
    const itemValue = String(obj[item])

    arr.push({ key: itemKey, value: itemValue })
    return arr
  }, [] as IOption[])
}

export const optionalField = <T extends object, K extends keyof T>(obj: T, property: K, defaultValue: string | number = '') => {
  return Object.hasOwn(obj, property) ? obj[property] : defaultValue
}

export const makeRange = (size: number) => {
  return Array(size).fill(0)
}

export const makeOptionsRange = (size: number): IOption[] => {
  return makeRange(size).map(
    (_, i) => ({
      key: i + 1,
      value: `${i + 1}`,
    }),
  )
}

export const getMediaProjectFormatIri = (id: string) => {
  return `/api/formats/${id}`
}

export const getGeoLocationIri = (id: string) => {
  return `/api/geo_locations/${id}`
}

export const getInitials = (name?: string) => {
  if (!name) { return '' }
  const names = name.split(' ')
  let secondSymbol = names[0]!.charAt(1)
  if (names.length === 2) {
    secondSymbol = names[1]!.charAt(0)
  }
  return `${names[0]!.charAt(0)}${secondSymbol}`
}

export const getIri = (id: string, resource: keyof paths) => {
  return `${resource}/${id}`
}

export const isEditorContentEmpty = (content: string = ''): boolean => {
  // Remove HTML tags and trim whitespace, ignoring <img> tags
  return content.replace(/<(?!img)[^>]*>/g, '').trim() === ''
}
